<template>
  <div class="container">
    <footer
      class="row row-cols-1 row-cols-sm-2 row-cols-md-5 py-5 my-5 border-top"
    >
      <div class="col mb-3">
        <router-link
          :to="{ name: 'Home' }"
          class="d-flex align-items-center mb-3 link-dark text-decoration-none"
        >
          <img class="bi me-2" width="240" src="../assets/logo_text.png" />
        </router-link>

        <p class="text-muted">
          <img
            src="../assets/zhangxiang_logo.jpg"
            height="30"
            alt="藏象科技"
            class="me-1"
          />Copyright © 2022 藏象科技.
          <span class="text-nowrap">All Rights Reserved</span>
        </p>
      </div>

      <div class="col mb-3"></div>

      <div class="col mb-3">
        <h5>
          <router-link
            :to="{
              name: 'Home',
              params: { region_id: $route.params.region_id },
            }"
            style="color: black"
            >返回主页</router-link
          >
        </h5>
        <ul class="nav flex-column">
          <li class="nav-item mb-2">
            <router-link
              :to="{ name: 'PageM', params: { id: 'aboutus', region_id: $route.params.region_id } }"
              class="nav-link p-0 text-muted"
              >关于我们</router-link
            >
          </li>
          <li class="nav-item mb-2">
            <router-link
              :to="{ name: 'PageM', params: { id: 'termsofservice', region_id: $route.params.region_id } }"
              class="nav-link p-0 text-muted"
              >服务条款</router-link
            >
          </li>
          <li class="nav-item mb-2">
            <router-link
              :to="{ name: 'PageM', params: { id: 'copyright', region_id: $route.params.region_id } }"
              class="nav-link p-0 text-muted"
              >版权声明</router-link
            >
          </li>
          <li class="nav-item mb-2">
            <router-link
              :to="{ name: 'PageM', params: { id: 'contactus', region_id: $route.params.region_id } }"
              class="nav-link p-0 text-muted"
              >联系我们</router-link
            >
          </li>
          <li class="nav-item mb-2">
            <router-link
              :to="{ name: 'PageM', params: { id: 'changelog', region_id: $route.params.region_id } }"
              class="nav-link p-0 text-muted"
              >更新日志</router-link
            >
          </li>
          <li class="nav-item mb-2">
            <router-link
              :to="{ name: 'PageM', params: { id: 'feedback', region_id: $route.params.region_id } }"
              class="nav-link p-0 text-muted"
              >意见反馈</router-link
            >
          </li>
        </ul>
      </div>

      <div class="col mb-3">
        <h5>联系方式</h5>
        <ul class="nav flex-column">
          <li class="nav-item mb-2">
            <span class="nav-link p-0 text-muted">网站联系人:</span>
          </li>
          <li class="nav-item mb-2">
            <span class="nav-link p-0 text-muted">隋先生</span>
          </li>
          <li class="nav-item mb-2">
            <span class="nav-link p-0 text-muted">联系方式: </span>
          </li>
          <li class="nav-item mb-2">
            <span class="nav-link p-0 text-muted">13840490199</span>
          </li>
          <li class="nav-item mb-2">
            <span class="nav-link p-0 text-muted">电子邮箱:</span>
          </li>
          <li class="nav-item mb-2">
            <a href="mailto:20086947@qq.com" class="nav-link p-0 text-muted"
              >20086947@qq.com</a
            >
          </li>
        </ul>
      </div>

      <div class="col mb-3">
        <h5>备案信息</h5>
        <ul class="nav flex-column">
          <li class="nav-item mb-2">
            <a class="nav-link p-0 text-muted">辽ICP备2022004196号-1</a>
          </li>
          <li class="nav-item mb-2">
            <a
              target="_blank"
              href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=21010602000734"
              class="nav-link p-0 text-muted"
              ><img src="../assets/beian.png" />
              辽公网安备 21010602000734号
            </a>
          </li>
        </ul>
      </div>
    </footer>
  </div>
</template>