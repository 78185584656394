import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import axios from "axios"

import { get_region_id_from_url } from './composables/Utils';

axios.defaults.baseURL = "https://www.timemarking.com/";
// axios.defaults.baseURL = "http://localhost:8001/";
// axios.defaults.baseURL = "http://localhost/";

router.afterEach((to, from, next) => {

    let region_id = get_region_id_from_url();
    let prefix = "";
    if (region_id == "21") {
        prefix = "辽宁";
    } else if (region_id == "2101") {
        prefix = "沈阳";
    }

    /* 路由发生变化修改页面title */
    if (to.meta.title) {
        document.title = `时空记忆 - ${prefix}${to.meta.title}`
    }
    // chrome
    document.body.scrollTop = 0
    // firefox
    document.documentElement.scrollTop = 0
    // safari
    window.pageYOffset = 0
    if (typeof (next) == "function") {
        next();
    }
});

import 'bootstrap/dist/css/bootstrap.min.css'
import "bootstrap/dist/js/bootstrap.min.js"

import 'bootstrap-icons/font/bootstrap-icons.css'

// import BootstrapVue3 from 'bootstrap-vue-3'
// import 'bootstrap-vue-3/dist/bootstrap-vue-3.css'

const app = createApp(App)
app.use(router)
// app.use(BootstrapVue3)
app.mount('#app')
