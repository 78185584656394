import { useRouter } from "vue-router";

// 转换字符串属性到 json
function convert_field_to_json(obj, field) {
  if (Object.prototype.hasOwnProperty.call(obj, field)) {
    if (typeof obj[field] == "string") {
      obj[field] = JSON.parse(obj[field]);
    }
  }
}

// 通过 url 获取 id
function get_id_from_url(url) {
  let arr = url.split("/")
  return arr[arr.length - 2]
}

//获取年份信息，如果是公元前，返回负数
function get_year(val, default_value) {
  let result = default_value;
  if (val) {
    result = parseInt(val.substring(0, 4));
    if (val.endsWith("BC")) {
      result = -result;
    }
  }
  return result;
}

//年份选择时的格式化函数
function get_year_text(bc_date) {
  let val = get_year(bc_date);
  return `${val < 0 ? "公元前" : ""}${parseInt(Math.abs(val))}年`;
}

function convert_mercator_to_lonlat(geometry) {
  // 转换坐标到经纬度
  function mercatorTolonlat(mercator) {
    var x = (mercator[0] / 20037508.34) * 180;
    var y = (mercator[1] / 20037508.34) * 180;
    y =
      (180 / Math.PI) *
      (2 * Math.atan(Math.exp((y * Math.PI) / 180)) - Math.PI / 2);
    return [x, y];
  }

  geometry = JSON.parse(JSON.stringify(geometry)); // deepCopy 一个对象
  // 转换坐标
  if (geometry.type == "Point") {
    geometry.coordinates = mercatorTolonlat(geometry.coordinates);
  } else if (geometry.type == "LineString") {
    geometry.coordinates = geometry.coordinates.map((arr) => {
      return mercatorTolonlat(arr);
    });
  } else {
    geometry.coordinates = geometry.coordinates.map((g) => {
      g = g.map((arr) => {
        return mercatorTolonlat(arr);
      });
      return g;
    });
  }
  return geometry;
}

function create_geojson_from_geometry(geometry) {
  geometry = convert_mercator_to_lonlat(geometry);

  //构建 geojson 对象
  let json = {
    type: "Feature",
    geometry: geometry,
    // properties: {
    //   name: "aaaaaa",
    // },
  };
  return json;
}


// 通过 url 获取 地区ID
function get_region_id_from_url(param_name = "region_id") {
  // 先通过路由参数获取
  let router = useRouter();
  let region_id = "";
  if (router && router.currentRoute) {
    region_id = router.currentRoute.value.params[param_name] ?? "";
  }
  if (region_id == "") {
    // 如果没有，再通过 url 前缀获取
    let url = new URL(window.location.href);
    // console.log(url.host)
    let prefix = url.host.split('.')[1];
    // 只处理辽宁和沈阳的情况
    if (prefix == 'ln') {
      region_id = '21';
    } else if (prefix == 'sy') {
      region_id = '2101';
    }

    if (region_id == "") {
      // 如果前两种获取地区码的方式失败了，尝试从 pathname 中获取
      let pathname = url.pathname;
      let arr = pathname.split('/');
      if (arr.length > 1 && /[\d]+/.test(arr[1])) {
        region_id = arr[1];
      }
    }
  }

  return region_id
}

export {
  convert_field_to_json,
  get_id_from_url,
  get_year_text,
  convert_mercator_to_lonlat,
  create_geojson_from_geometry,
  get_region_id_from_url,
}